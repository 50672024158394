import Vue from 'vue';
import AntDesign, { Icon } from 'ant-design-vue';
import moment from 'moment';

import VueClipboard from 'vue-clipboard2';
import App from './App.vue';
import i18n from './utils/i18n'; // todo remove i18n
import router from './router';
// import './utils/resize';
import 'ant-design-vue/dist/antd.less';
import './styles/index.scss';

Icon.setTwoToneColor('#0045ff');

Vue.config.productionTip = false;

Vue.use(AntDesign);
Vue.use(VueClipboard);

// Vue.filter("formatDate", function(value) {
//   return moment(value).format("YYYY-MM-DD HH:mm");
// });
Vue.prototype.$moment = moment;

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app');
