import Vue from 'vue';
import Router from 'vue-router';

const originalPush = Router.prototype.push;

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {});
};

Vue.use(Router);

const constantRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home/index.vue'),
  },
  {
    path: '/business',
    name: 'business',
    component: () => import('@/views/Business/index.vue'),
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/My/index.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/Register/index.vue'),
  },
  {
    path: '/registerSuccess',
    name: 'registerSuccess',
    component: () => import('@/views/Register/component/RegisterSuccess.vue'),
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import('@/views/ResetPassword/index.vue'),
  },
  {
    path: '/resetPasswordSuccess',
    name: 'resetPasswordSuccess',
    component: () => import('@/views/ResetPassword/component/ResetPasswordSuccess.vue'),
  },
  {
    path: '/onlinePayment',
    name: 'onlinePayment',
    component: () => import('@/views/OnlinePayment/index.vue'),
  },
  {
    path: '/buy',
    name: 'buy',
    component: () => import('@/views/Buy/index.vue'),
  },
  {
    path: '/openbuy',
    name: 'openbuy',
    component: () => import('@/views/OpenCBuy/index.vue'),
  },
  {
    path: '/custody',
    name: 'custody',
    component: () => import('@/views/Custody/index.vue'),
  },
  {
    path: '/kyc',
    name: 'kyc',
    component: () => import('@/views/Kyc/index.vue'),
  },
  {
    path: '/kyc-card',
    name: 'kyc-card',
    component: () => import('@/views/Card/index.vue'),
  },
  {
    path: '/cryptoBuy',
    name: 'card',
    component: () => import('@/views/CryptoBuy/index.vue'),
  },
  {
    path: '/applyCard',
    name: 'applyCard',
    component: () => import('@/views/ApplyCard/index.vue'),
  },
  {
    path: '/cardDetails',
    name: 'cardDetails',
    component: () => import('@/views/CardDetails/index.vue'),
  },
  {
    path: '/cardInfo',
    name: 'cardInfo',
    component: () => import('@/views/CardInfo/index.vue'),
  },
  {
    path: '/cardRecharge',
    name: 'cardRecharge',
    component: () => import('@/views/CardRecharge/index.vue'),
  },
  // {
  //   path: "/quick-buy",
  //   name: "quick-buy",
  //   component: () => import("@/views/QuickBuy/index.vue")
  // },
  {
    path: '/card',
    name: 'CardSolutions',
    component: () => import('@/views/CardSolutions/index.vue'),
  },
  {
    path: '/business-card',
    name: 'BusinessCard',
    component: () => import('@/views/BusinessCard/index.vue'),
  },
  {
    path: '/business-solutions',
    name: 'BusinessSolutions',
    component: () => import('@/views/BusinessSolutions/index.vue'),
  },
  {
    path: '/business-onRamps',
    name: 'BusinessOnRamps',
    component: () => import('@/views/BusinessOnRamps/index.vue'),
  },
  {
    path: '/contact-sales',
    name: 'contact-sales',
    component: () => import('@/views/ContactSales/index.vue'),
  },
  {
    path: '/brand',
    name: 'brand',
    component: () => import('@/views/Brand/index.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutUS/index.vue'),
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/views/Terms.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/Privacy/index.vue'),
  },
  {
    path: '/cookie',
    name: 'Cookie',
    component: () => import('@/views/CookiePolicy.vue'),
  },
  {
    path: '/amlPolicy',
    name: 'AMLKYCPolicy',
    component: () => import('@/views/AMLKYCPolicy.vue'),
  },
  {
    path: '/conditions',
    name: 'Conditions',
    component: () => import('@/views/Conditions.vue'),
  },
];

const createRouter = () => new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
